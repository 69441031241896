import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Container, IconButton, Stack, Typography } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone'
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import { ProductButtons } from "./components";
import LogoImage_1 from "../../assets/logo.png"
import LogoImage_2 from "../../assets/logo_2.png"
import {products} from "../../DATA"

const Controls = ({productId}) => {

    const navigate = useNavigate()

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <IconButton size="large"
                onClick={() => navigate(`/scene/${productId}`)}
            >
                <ThreeDRotationIcon fontSize="inherit" sx={{color: "#000000"}} />
            </IconButton>
            <IconButton size="large"
                href="https://addon-edu.ru/contacts/"
                target="_blank"
                rel="noreferrer"
            >
                <PhoneIcon fontSize="inherit" sx={{color: "#000000"}} />
            </IconButton>
        </Stack>
    )
}

const ProductBanner = ({product}) => {
    return (
        <Box
            sx={{width: 325, height: 500, boxShadow: "2px 2px 2px 2px lightgrey", 
                bgcolor: "white", m: 1, borderRadius: 2, overflow: "hidden", display: "inline-block"
            }}
        >
            <Box sx={{width: "100%", height: 320, overflow: "hidden"}}>
                <img
                    alt="product"
                    src={product?.preview}
                    style={{height: 320, width: 325, objectFit: "cover"}}
                />
            </Box>
            <Stack
                justifyContent="space-between"
                sx={{textAlign: "left", m: 1, px: 1, height: 165}}
            >
                <Typography 
                    sx={{
                        fontWeight: "bold",
                        display: "-webkit-box",
                        overflow: "hidden", textOverflow: "ellipsis",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                    }}
                >
                    {product?.title}
                </Typography>
                <Typography variant="h5"
                    sx={{color: "primary.red", fontWeight: "bold"}}
                >
                    {product?.cost} руб.
                </Typography>
                <Controls productId={product?.id}/>
            </Stack>
        </Box>
    )
}

const ProductInfo = ({product}) => {
    return (
        <Box sx={{
                width: 325, textAlign: "left", px: 1,
                m: 1, borderRadius: 2, overflow: "hidden", display: "inline-block"
            }}
        >
            <Typography variant="h6" sx={{fontWeight: "bold"}}>
                Описание
            </Typography>
            <Typography>
                {product?.summary}
            </Typography>
        </Box>
    )
}

const ProductSpecs = ({product}) => {
    return (
        <Box sx={{
            width: 325, textAlign: "left", px: 1,
            m: 1, borderRadius: 2, overflow: "hidden", display: "inline-block"
        }}>
            <Typography variant="h6" sx={{fontWeight: "bold"}}>
                Характеристики
            </Typography>
            {product?.details.map(spec =>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    key={spec[0]} 
                    sx={{ bgcolor: "#f2f2f2", my: 1, p: 0.5}}
                >
                    <Typography sx={{fontWeight: "bold"}}>
                        {spec[0]}:
                    </Typography>
                    <Typography sx={{textDecoration: 'underline'}}>
                        {spec[1]}
                    </Typography>
                </Stack>
            )}
        </Box>
    )
}


export default function ProductPage() {

    const params = useParams()
    const navigate = useNavigate()

    const [product, setProduct] = useState()

    useEffect(() => {
        document.title = "ADDON EXPO • Оборудование"
    }, []);

    useEffect(() => {
        const result = products.find(obj => obj.id === Number(params.productId))
        !result && navigate('/')
        setProduct(result)
    }, [params.productId, navigate])
    
    return (
        <Container maxWidth="xl" sx={{py: 7}}>
            <ProductButtons />
            <ProductBanner product={product} />
            <ProductInfo product={product} />
            <ProductSpecs product={product} />
            <Stack direction="row"
                spacing={1}
                sx={{
                    bgcolor: "white", height: 200, borderRadius: 2, 
                    my: 2, boxShadow: "2px 2px 2px 2px lightgrey",
                    justifyContent: "space-around", alignItems: "center",
                    overflow: "hidden", position: "relative"
                }}
            >
                <Typography variant="h5" sx={{fontWeight: "bold"}}>
                    Есть вопросы?
                </Typography>
                <Box sx={{zIndex: 10,}}>
                    <Typography sx={{fontWeight: "bold"}}>
                        +7 (965) 281-21-44
                    </Typography>
                    <Typography sx={{fontWeight: "bold"}}>
                        igir585@gmail.com
                    </Typography>
                </Box>
            </Stack>
        </Container>
    )
}