import {useCallback, useEffect, useState, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {Box, Button, Pagination, Stack, Typography} from '@mui/material'
import { BackButton, ControlPanel } from './Buttons'
import { AnnotationsMask } from './Annotations'
import EnvImage from '../../models/aircraft_workshop.hdr'
import LogoBox from './LogoBox'

import {products} from "../../DATA"

const drawLine = (svgLine, dotHotspot1, dotHotspot2, dimensionHotspot) => {
    if (dotHotspot1 && dotHotspot2) {
      svgLine.setAttribute('x1', dotHotspot1.canvasPosition.x);
      svgLine.setAttribute('y1', dotHotspot1.canvasPosition.y);
      svgLine.setAttribute('x2', dotHotspot2.canvasPosition.x);
      svgLine.setAttribute('y2', dotHotspot2.canvasPosition.y);

      if (dimensionHotspot && !dimensionHotspot.facingCamera) {
        svgLine.classList.add('hide');
      }
      else {
        svgLine.classList.remove('hide');
      }
    }
}

export default function Viewer() {

    const params = useParams()
    const navigate = useNavigate()
    const modelRef = useRef(null)

    const [product, setProduct] = useState()
    const [items, setItems] = useState([])

    const [modv, setModv] = useState()

    const [sizesVisible, setSizesVisible] = useState(false)
    const [animation, setAnimation] = useState(true)
    const [infoVisible, setInfoVisible] = useState(false)
    const [itemNumber, setItemNumber] = useState(0)

    const [animationName, setAnimationName] = useState("")

    const renderSizes = useCallback(() => {
        const modelViewer = modelRef.current
    
        const dimLines = modelViewer.querySelectorAll('line')
        const renderSVG = () => {
            drawLine(dimLines[0], modelViewer.queryHotspot('hotspot-dot+X-Y+Z'), modelViewer.queryHotspot('hotspot-dot+X-Y-Z'), modelViewer.queryHotspot('hotspot-dim+X-Y'));
            drawLine(dimLines[1], modelViewer.queryHotspot('hotspot-dot+X-Y-Z'), modelViewer.queryHotspot('hotspot-dot+X+Y-Z'), modelViewer.queryHotspot('hotspot-dim+X-Z'));
            drawLine(dimLines[2], modelViewer.queryHotspot('hotspot-dot+X+Y-Z'), modelViewer.queryHotspot('hotspot-dot-X+Y-Z')); // always visible
            drawLine(dimLines[3], modelViewer.queryHotspot('hotspot-dot-X+Y-Z'), modelViewer.queryHotspot('hotspot-dot-X-Y-Z'), modelViewer.queryHotspot('hotspot-dim-X-Z'));
            drawLine(dimLines[4], modelViewer.queryHotspot('hotspot-dot-X-Y-Z'), modelViewer.queryHotspot('hotspot-dot-X-Y+Z'), modelViewer.queryHotspot('hotspot-dim-X-Y'));
        }

        modelViewer.addEventListener('load', () => {
            const center = modelViewer.getBoundingBoxCenter();
            const size = modelViewer.getDimensions();
            const x2 = size.x / 2;
            const y2 = size.y / 2;
            const z2 = size.z / 2;
        
            modelViewer.updateHotspot({
              name: 'hotspot-dot+X-Y+Z',
              position: `${center.x + x2} ${center.y - y2} ${center.z + z2}`
            });
        
            modelViewer.updateHotspot({
              name: 'hotspot-dim+X-Y',
              position: `${center.x + x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim+X-Y"]').textContent =
                `${(size.z * 100).toFixed(0)} см`;
        
            modelViewer.updateHotspot({
              name: 'hotspot-dot+X-Y-Z',
              position: `${center.x + x2} ${center.y - y2} ${center.z - z2}`
            });
        
            modelViewer.updateHotspot({
              name: 'hotspot-dim+X-Z',
              position: `${center.x + x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim+X-Z"]').textContent =
                `${(size.y * 100).toFixed(0)} см`;
        
            modelViewer.updateHotspot({
              name: 'hotspot-dot+X+Y-Z',
              position: `${center.x + x2} ${center.y + y2} ${center.z - z2}`
            });
        
            modelViewer.updateHotspot({
              name: 'hotspot-dim+Y-Z',
              position: `${center.x} ${center.y + y2 * 1.1} ${center.z - z2 * 1.1}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim+Y-Z"]').textContent =
                `${(size.x * 100).toFixed(0)} см`;
        
            modelViewer.updateHotspot({
              name: 'hotspot-dot-X+Y-Z',
              position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`
            });
        
            modelViewer.updateHotspot({
              name: 'hotspot-dim-X-Z',
              position: `${center.x - x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim-X-Z"]').textContent =
                `${(size.y * 100).toFixed(0)} см`;
                
            modelViewer.updateHotspot({
              name: 'hotspot-dot-X-Y-Z',
              position: `${center.x - x2} ${center.y - y2} ${center.z - z2}`
            });

            modelViewer.updateHotspot({
              name: 'hotspot-dim-X-Y',
              position: `${center.x - x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim-X-Y"]').textContent =
                `${(size.z * 100).toFixed(0)} см`;
        
            modelViewer.updateHotspot({
              name: 'hotspot-dot-X-Y+Z',
              position: `${center.x - x2} ${center.y - y2} ${center.z + z2}`
            });
        
            renderSVG();
        
            modelViewer.addEventListener('camera-change', renderSVG)
    })}, [])

    useEffect(() => {
      const result = products.find(obj => obj.id === Number(params.productId))
      !result && navigate('/')
      setProduct(result)
      setItems(result.items)
      console.log(result.items)
    }, [params.productId, navigate])
    

    useEffect(() => {
        document.title = "ADDON EXPO • 3D-сцена"
    }, []);

    useEffect(() => {
      const modelViewer = modelRef.current
      setModv(modelViewer)
    }, [modelRef])
    
    useEffect(() => {
        renderSizes()
    }, [renderSizes])
    
    useEffect(() => {

        const modelViewer = modelRef.current

        const dimElements = [...modelViewer.querySelectorAll('button'), modelViewer.querySelector('#dimLines')]

        const setVisibility = (visible) => {
            dimElements.forEach((element) => {
                visible 
                    ? element.classList.remove('hide')
                    : element.classList.add('hide')
                setSizesVisible(visible)
            })
        }
        setVisibility(sizesVisible)

    }, [sizesVisible])

    useEffect(() => {
      const modelViewer = modelRef.current
      if (animation) {
        modelViewer.play()
      } else {
        modelViewer.pause()
      }
    }, [animation])
    
    return (
      <Box sx={{height: "100dvh", width: "100%", display: 'flex', position: "static",
        justifyContent: 'center',
        alignItems: 'center', bgcolor: "primary.white"}}
      >
        <LogoBox />
        <model-viewer
          id="main-panel"
          ref={modelRef}
          src={items[itemNumber]?.src}
          camera-orbit="45deg 55deg 4m"
          shadow-intensity="2"
          max-camera-orbit="auto 90deg auto"
          style={{width: "100%", height: "100%", backgroundColor: "unset"}} 
          autoplay
          ar 
          environment-image={EnvImage}
          exposure="1.18" shadow-softness="0.39"
          //ar-modes="scene-viewer quick-look"
          camera-controls  ar-scale="fixed"
          animation-name={animationName}
          skybox-height="1.5m"
          // skybox-image={background}
        >
          <div className="annotation" 
            style={{position: "absolute", top: 65, left: 60, height: 35, width: 90, background: 'transparent'}}
          >
            <BackButton />
          </div>
          <button slot="ar-button" />
          <button slot="hotspot-dot+X-Y+Z" className="dot" data-position="1 -1 1" data-normal="1 0 0"></button>
          <button slot="hotspot-dim+X-Y" className="dim" data-position="1 -1 0" data-normal="1 0 0"></button>
          <button slot="hotspot-dot+X-Y-Z" className="dot" data-position="1 -1 -1" data-normal="1 0 0"></button>
          <button slot="hotspot-dim+X-Z" className="dim" data-position="1 0 -1" data-normal="1 0 0"></button>
          <button slot="hotspot-dot+X+Y-Z" className="dot" data-position="1 1 -1" data-normal="0 1 0"></button>
          <button slot="hotspot-dim+Y-Z" className="dim" data-position="0 -1 -1" data-normal="0 1 0"></button>
          <button slot="hotspot-dot-X+Y-Z" className="dot" data-position="-1 1 -1" data-normal="0 1 0"></button>
          <button slot="hotspot-dim-X-Z" className="dim" data-position="-1 0 -1" data-normal="-1 0 0"></button>
          <button slot="hotspot-dot-X-Y-Z" className="dot" data-position="-1 -1 -1" data-normal="-1 0 0"></button>
          <button slot="hotspot-dim-X-Y" className="dim" data-position="-1 -1 0" data-normal="-1 0 0"></button>
          <button slot="hotspot-dot-X-Y+Z" className="dot" data-position="-1 -1 1" data-normal="-1 0 0"></button>
          <svg id="dimLines" width="100%" height="100%" 
            xmlns="http://www.w3.org/2000/svg" 
            className="dimensionLineContainer"
          >
              <line className="dimensionLine"></line>
              <line className="dimensionLine"></line>
              <line className="dimensionLine"></line>
              <line className="dimensionLine"></line>
              <line className="dimensionLine"></line>
          </svg>
          <AnnotationsMask 
            infoVisible={infoVisible} 
            annotations={items[itemNumber]?.annotations} 
          />
          <ControlPanel 
            sizesVisible={sizesVisible} 
            setSizesVisible={setSizesVisible}
            infoVisible={infoVisible}
            setInfoVisible={setInfoVisible}
            animation={animation}
            setAnimation={setAnimation}
            items={items}
            itemNumber={itemNumber}
            setItemNumber={setItemNumber}
            mv={modv}
            animationName={animationName}
            setAnimationName={setAnimationName}
          />
        </model-viewer>
      </Box>
    )
}