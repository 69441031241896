import { Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react';
import './App.css';
import {mainTheme} from './assets/theme'
import Layout from './layouts/Main';
import {ProductPage, ProductsPage} from './pages/catalog'
import Viewer from './pages/components/Viewer';

function App() {
  
  return (
    <ThemeProvider theme={mainTheme}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={
            <ProductsPage />
          } />
          <Route path="/product/:productId" element={
            <ProductPage />
          } />
          <Route path="/scene/:productId" element={
            <Viewer />
          } />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
