import { Button, Container, Stack } from "@mui/material"

export default function Subheader(props) {
    return (
        <Container maxWidth="xl" sx={{mx: "auto", my: 1}}>
            <Stack direction="row"
                justifyContent="space-around"
                alignItems="center"

                sx={{bgcolor: "transparent", width: "100%"}}
            >
                {props.children}
            </Stack>
        </Container>
    )
}