import { createTheme } from '@mui/material';

const mainTheme = createTheme({
    palette: {
        primary: {
            red: "#A0051C",
            main: "#21243F",
            dark: "#0561a5",
        },
        secondary: {
            red: "green",
            main: "#0561a5",
            dark: "blue",
        }
    }
});

export {mainTheme};