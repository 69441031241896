import { useNavigate } from "react-router-dom"
import { Box, Button, Container, IconButton, Link, Stack, Toolbar, Typography } from "@mui/material"
import SortIcon from '@mui/icons-material/Sort'
import TuneIcon from '@mui/icons-material/Tune'
import PhoneIcon from '@mui/icons-material/Phone'
import BarChartIcon from '@mui/icons-material/BarChart';
import Subheader from "../../layouts/Subheader"
import { BackButton } from "../components/Buttons"

const FilterButtons = () => {

    return (
        <Subheader>
            <Button disabled startIcon={<SortIcon />}>
                По популярности
            </Button>
            <Button disabled startIcon={<TuneIcon />}>
                Фильтры
            </Button>
        </Subheader>
    )
}

export {FilterButtons}

const ProductButtons = () => {

    return (
        <Subheader>
            <BackButton />
            <Button disabled startIcon={<BarChartIcon />}>
                Сравнить
            </Button>
        </Subheader>
    )
}

export {ProductButtons}

const SmallProductCard = ({product}) => {

    const navigate = useNavigate()

    return (
        <Box
            sx={{width: 325, height: 510, boxShadow: "2px 2px 2px 2px lightgrey", 
                bgcolor: "white", m: 1, display: "inline-block", borderRadius: 2, overflow: "hidden"
            }}
        >
            <Box sx={{width: "100%", height: 320, overflow: "hidden"}}>
                <img
                    alt="product"
                    src={product.preview}
                    style={{height: 320, width: 325, objectFit: "cover"}}
                />
            </Box>
            <Stack justifyContent="space-between"
                sx={{textAlign: "left", m: 1, px: 1, height: 175}}
            >
                <Link
                    onClick={() => navigate(`/product/${product.id}`)}
                    underline="hover"  
                    sx={{
                        fontWeight: "bold",
                        display: "-webkit-box",
                        overflow: "hidden", textOverflow: "ellipsis",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                    }}
                >
                    {product.title}
                </Link>
                <Typography sx={{fontSize: 14, overflow: "hidden",
                        textOverflow: "ellipsis", 
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                    }}
                >
                    {product.summary}
                </Typography>
                <Stack direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{width: "100%"}}
                >
                    <Typography variant="h5"
                        sx={{color: "primary.red", fontWeight: "bold"}}
                    >
                        {product.cost} руб.
                    </Typography>
                    <Box >
                        <IconButton disabled>
                            <BarChartIcon sx={{ height: 30, width: 30, border: 1}} />
                        </IconButton>
                        <IconButton disabled>
                            <PhoneIcon sx={{ height: 30, width: 30, border: 1}} />
                        </IconButton>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    )
}

const ProductsBox = ({products}) => {
    return (
        <Box >
            <Box sx={{ width: "100%"}}>
            {products.map(product =>
                <SmallProductCard
                    key={product.id}
                    product={product}
                />
            )}
             {products.map(product =>
                <SmallProductCard
                    key={product.id}
                    product={product}
                />
            )}
             {products.map(product =>
                <SmallProductCard
                    key={product.id}
                    product={product}
                />
            )}
             {products.map(product =>
                <SmallProductCard
                    key={product.id}
                    product={product}
                />
            )}
             {products.map(product =>
                <SmallProductCard
                    key={product.id}
                    product={product}
                />
            )}
             {products.map(product =>
                <SmallProductCard
                    key={product.id}
                    product={product}
                />
            )}
             {products.map(product =>
                <SmallProductCard
                    key={product.id}
                    product={product}
                />
            )}
             {products.map(product =>
                <SmallProductCard
                    key={product.id}
                    product={product}
                />
            )}
             {products.map(product =>
                <SmallProductCard
                    key={product.id}
                    product={product}
                />
            )}
            </Box>
        </Box>
    )
}

export {ProductsBox}