import printer_1 from "./models/printer_1.glb"
import printer_2 from "./models/printer_2.glb"
import printer_3 from "./models/printer_3.glb"
import printer_4 from "./models/printer_4.glb"
import printer_5 from "./models/printer_5.glb"
import printer_1_preview from "./models/printer_1_preview.png"
import printer_5_preview from "./models/printer_5_preview.png"

const products = [
    {
        "id": 1,
        "title": "3D-принтер Creality Ender-3",
        "summary": "3D принтер Creality Ender-3 — достаточно компактное оборудование с большой областью печати. Данное устройство использует технологию FDM и позволяет работать с распространенными пластиками. С помощью Ender-3 Neo можно изготавливать различные коммерческие и рекламные изделия, детали механизмов, прототипы устройств, архитектурные и строительные макеты и многое другое.",
        "cost": 16000,
        "preview": printer_1_preview,
        "details": [
            ["Технология печати", "FDM/FFF"],
            ["Диаметр нити", "1.75 мм"],
            ["Тип материала", "ABS, PLA, Flex"],
            ["Размер области построения (мм)", "220х220х250"],
            ["Материал корпуса", "сталь"],
            ["Температура стола", "до 100 ℃"],
            ["Температура экструдера", "до 255 ℃"],
            ["Толщина слоя", "0.1-0.4 мм"],
            ["Скорость печати", "до 180 мм/с"],
            ["Точность", "± 0.1 мм"],
            ["Вес", "6.6 кг"]
        ],
        "items": [
            {
                "id": 1,
                "src": printer_1,
                "name": "Стандарт",
                "annotations": [
                    ["hotspot-1", "152 0 524 508 532 0.145 0.297 0.558", "Ручка управления", "Главный элеменит контроля процессов принтера"],
                    ["hotspot-2", "151 0 0 1 2 0.140 0.442 0.418", "Дисплей", "Используются для отображения ключевой информации во время печати и настройки"],
                    ["hotspot-3", "11 0 2419 2331 2426 0.040 0.325 0.635", "Блок управления", "Мозги принтера, здесь происходят все вычисления во время печати"],
                    ["hotspot-4", "156 0 1810 1833 1832 0.581 0.390 0.029", "TF-слот и USB-порт", "Интерфейсы для подключения принтера к компьютеру и загрузки моделей на печать с карты памяти"],
                    ["hotspot-5", "34 0 422 424 463 0.067 0.814 0.119", "Горячий стол", "Нагреваемая печатная плоская поверхность, обеспечивает равномерное распределения расплавленного филамента для формирования отпечатка"],
                    ["hotspot-6", "83 5 762 751 764 0.694 0.059 0.247", "Хотенд", "Главный компонент принтера, расплавляет и позиционирует филамент для формирования отпечатка"],
                    ["hotspot-7", "148 3 107 109 108 0.246 0.711 0.043", "Питание", "Здесь происходит подключение принтера к сети и его запуск"],
                    ["hotspot-8", "1 1 9 11 10 0.818 0.152 0.031", "Y-двигатель", "Обеспечивает точное движение стола вперед-назад"],
                    ["hotspot-9", "51 0 297 292 298 0.102 0.799 0.099", "Z-двигатель", "Обеспечивает точное движение хотенда вверх-вниз"],
                    ["hotspot-10", "57 1 148 144 154 0.644 0.098 0.258", "X-двигатель", "Обеспечивает точное движение хотенда влево-вправо"]
                ]
            },  
            {
                "id": 2,
                "src": printer_2,
                "name": "Новый обдув",
                "annotations": [
                    ["hotspot-1", "152 0 524 508 532 0.100 0.306 0.595", "Ручка управления", "Главный элеменит контроля процессов принтера"],
                    ["hotspot-2", "151 0 1 3 2 0.291 0.042 0.667", "Дисплей", "Используются для отображения ключевой информации во время печати и настройки"],
                    ["hotspot-3", "156 0 772 773 771 0.074 0.033 0.893", "Блок управления", "Мозги принтера, здесь происходят все вычисления во время печати"],
                    ["hotspot-4", "156 0 1828 1813 1832 0.004 0.782 0.214", "TF-слот и USB-порт", "Интерфейсы для подключения принтера к компьютеру и загрузки моделей на печать с карты памяти"],
                    ["hotspot-5", "34 0 422 424 463 0.234 0.642 0.123", "Горячий стол", "Нагреваемая печатная плоская поверхность, обеспечивает равномерное распределения расплавленного филамента для формирования отпечатка"],
                    ["hotspot-6", "92 0 688 687 690 0.180 0.146 0.674", "Хотенд", "Главный компонент принтера, расплавляет и позиционирует филамент для формирования отпечатка"],
                    ["hotspot-7", "148 3 103 105 104 0.117 0.587 0.296", "Питание", "Здесь происходит подключение принтера к сети и его запуск"],
                    ["hotspot-8", "1 1 0 1 2 0.254 0.482 0.264", "Y-двигатель", "Обеспечивает точное движение стола вперед-назад"],
                    ["hotspot-9", "51 0 99 101 100 0.528 0.256 0.216", "Z-двигатель", "Обеспечивает точное движение хотенда вверх-вниз"],
                    ["hotspot-10", "57 1 8 9 10 0.572 0.278 0.150", "X-двигатель", "Обеспечивает точное движение хотенда влево-вправо"],
                    ["hotspot-11", "104 0 12506 12507 12504 0.032 0.619 0.348", "Двойной обдув", "Двухсторонний обдув обеспечивает равномерное охлаждение горячего филамента"]
                ]
            },  
            {
                "id": 3,
                "src": printer_3,
                "name": "Катушка",
                "annotations": [
                    ["hotspot-1", "156 0 524 508 532 0.069 0.350 0.581", "Ручка управления", "Главный элеменит контроля процессов принтера"],
                    ["hotspot-2", "155 0 0 1 2 0.093 0.350 0.557", "Дисплей", "Используются для отображения ключевой информации во время печати и настройки"],
                    ["hotspot-3", "11 0 1362 1363 1364 0.399 0.038 0.563", "Блок управления", "Мозги принтера, здесь происходят все вычисления во время печати"],
                    ["hotspot-4", "160 0 1828 1813 1832 0.028 0.686 0.286", "TF-слот и USB-порт", "Интерфейсы для подключения принтера к компьютеру и загрузки моделей на печать с карты памяти"],
                    ["hotspot-5", "34 0 419 421 426 0.474 0.009 0.516", "Горячий стол", "Нагреваемая печатная плоская поверхность, обеспечивает равномерное распределения расплавленного филамента для формирования отпечатка"],
                    ["hotspot-6", "93 0 688 687 690 0.453 0.146 0.401", "Хотенд", "Главный компонент принтера, расплавляет и позиционирует филамент для формирования отпечатка"],
                    ["hotspot-7", "152 3 140 141 142 0.435 0.225 0.340", "Питание", "Здесь происходит подключение принтера к сети и его запуск"],
                    ["hotspot-8", "1 1 41 43 72 0.635 0.052 0.313", "Y-двигатель", "Обеспечивает точное движение стола вперед-назад"],
                    ["hotspot-9", "51 0 328 307 329 0.206 0.730 0.065", "Z-двигатель", "Обеспечивает точное движение хотенда вверх-вниз"],
                    ["hotspot-10", "57 1 8 9 10 0.598 0.215 0.186", "X-двигатель", "Обеспечивает точное движение хотенда влево-вправо"],
                    ["hotspot-11", "145 0 18068 18070 18069 0.376 0.335 0.289", "Крепеж филамента", "Крепеж обеспечивает плавную подачу филамента из катушки в фидер за счет крепления над областью печати, а не сбоку"]
                ]
            },
            {
                "id": 4,
                "src": printer_4,
                "name": "Обдув + Катушка",
                "annotations": [
                    ["hotspot-1", "156 0 524 508 532 0.069 0.350 0.581", "Ручка управления", "Главный элеменит контроля процессов принтера"],
                    ["hotspot-2", "155 0 0 1 2 0.093 0.350 0.557", "Дисплей", "Используются для отображения ключевой информации во время печати и настройки"],
                    ["hotspot-3", "11 0 1362 1363 1364 0.399 0.038 0.563", "Блок управления", "Мозги принтера, здесь происходят все вычисления во время печати"],
                    ["hotspot-4", "160 0 1828 1813 1832 0.028 0.686 0.286", "TF-слот и USB-порт", "Интерфейсы для подключения принтера к компьютеру и загрузки моделей на печать с карты памяти"],
                    ["hotspot-5", "34 0 419 421 426 0.474 0.009 0.516", "Горячий стол", "Нагреваемая печатная плоская поверхность, обеспечивает равномерное распределения расплавленного филамента для формирования отпечатка"],
                    ["hotspot-6", "93 0 688 687 690 0.453 0.146 0.401", "Хотенд", "Главный компонент принтера, расплавляет и позиционирует филамент для формирования отпечатка"],
                    ["hotspot-7", "152 3 140 141 142 0.435 0.225 0.340", "Питание", "Здесь происходит подключение принтера к сети и его запуск"],
                    ["hotspot-8", "1 1 41 43 72 0.635 0.052 0.313", "Y-двигатель", "Обеспечивает точное движение стола вперед-назад"],
                    ["hotspot-9", "51 0 328 307 329 0.206 0.730 0.065", "Z-двигатель", "Обеспечивает точное движение хотенда вверх-вниз"],
                    ["hotspot-10", "57 1 8 9 10 0.598 0.215 0.186", "X-двигатель", "Обеспечивает точное движение хотенда влево-вправо"],
                    ["hotspot-11", "145 0 18068 18070 18069 0.376 0.335 0.289", "Крепеж филамента", "Крепеж обеспечивает плавную подачу филамента из катушки в фидер за счет крепления над областью печати, а не сбоку"],
                    ["hotspot-12", "104 0 11704 11706 11705 0.389 0.523 0.087", "Двойной обдув", "Двухсторонний обдув обеспечивает равномерное охлаждение горячего филамента"]
                ]
            }
        ]        
    },
    {
        "id": 2,
        "title": "3D принтер Original Prusa i3 MK3s",
        "summary": "Original Prusa i3 MK3S + - последняя версия отмеченных наградами 3D-принтеров Prusa. В кампании модернизировали MK3S с помощью нового датчика SuperPINDA для улучшенной калибровки первого слоя, добавили высококачественные подшипники Misumi и различные полезные конструктивные изменения, чтобы упростить сборку и обслуживание принтера. MK3S + включает в себя все проверенные функции предыдущих моделей, в том числе подогреваемый стол со съемными листами для печати из пружинной стали PEI, автоматическое выравнивание сетчатого слоя.",
        "cost": 127000,
        "preview": printer_5_preview,
        "details": [
            ["Технология печати", "FDM/FFF"],
            ["Диаметр нити", "1.75 мм"],
            ["Тип материала", "ABS, PLA, Flex"],
            ["Размер области построения (мм)", "250х210х210"],
            ["Материал корпуса", "Алюминий"],
            ["Температура стола", "до 120 ℃"],
            ["Температура экструдера", "до 300 ℃"],
            ["Толщина слоя", "0.05-0.35 мм"],
            ["Скорость печати", "до 200 мм/с"],
            ["Точность", "± 0.1 мм"],
            ["Вес", "7 кг"]
        ],
        "items": [
            {
                "id": 5,
                "src": printer_5,
                "annotations": [
                    ["hotspot-1", "35 0 30947 30949 30995 0.289 0.378 0.333", "Ручка управления", "Главный элеменит контроля процессов принтера"],
                    ["hotspot-2", "55 0 3 2 4 0.003 0.864 0.133", "Дисплей", "Используются для отображения ключевой информации во время печати и настройки"],
                    ["hotspot-3", "69 0 20524 20525 20536 0.291 0.332 0.377", "Блок управления", "Мозги принтера, здесь происходят все вычисления во время печати"],
                    ["hotspot-4", "35 0 13547 13548 13549 0.068 0.867 0.065", "TF-слот и USB-порт", "Интерфейсы для подключения принтера к компьютеру и загрузки моделей на печать с карты памяти"],
                    ["hotspot-5", "41 0 123 124 125 0.279 0.392 0.329", "Горячий стол", "Нагреваемая печатная плоская поверхность, обеспечивает равномерное распределения расплавленного филамента для формирования отпечатка"],
                    ["hotspot-6", "58 0 170 167 182 0.376 0.175 0.449", "Хотенд", "Главный компонент принтера, расплавляет и позиционирует филамент для формирования отпечатка"],
                    ["hotspot-7", "22 0 11174 11175 11176 0.265 0.654 0.082", "Питание", "Здесь происходит подключение принтера к сети и его запуск"],
                    ["hotspot-8", "47 0 138 137 141 0.824 0.036 0.140", "Y-двигатель", "Обеспечивает точное движение стола вперед-назад"],
                    ["hotspot-9", "56 0 1666 1665 1669 0.517 0.427 0.056", "Z-двигатель", "Обеспечивает точное движение хотенда вверх-вниз"],
                    ["hotspot-10", "31 0 126 127 128 0.065 0.470 0.465", "X-двигатель", "Обеспечивает точное движение хотенда влево-вправо"]
                ]
            }
        ]        
    },    
]

export {products}