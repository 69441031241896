import { useEffect } from "react";
import { Container, Box } from "@mui/material";
import {FilterButtons, ProductsBox} from "./components"
import {products} from "../../DATA"

export default function ProductsPage() {

    useEffect(() => {
        document.title = "ADDON EXPO • Каталог"
    }, []);

    return (
        <Container maxWidth="xl" sx={{pt: 7}}>
            <FilterButtons />
            <ProductsBox products={products} />
        </Container>
    )
}