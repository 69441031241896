import { Stack, Typography } from "@mui/material"
import LogoImage from '../../assets/logo_2.png'
import appInfo from '../../app.json'

const LogoBox = () => {
    return (
      <Stack 
        style={{background: "linear-gradient(210deg, #ffffff, #0d436e)"}}
        sx={{position: "absolute", textAlign: "center", height: '100%', width: "100%"}}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <img 
          alt="logo"
          src={LogoImage}
          style={{width: "70%", opacity: "70%", maxWidth: "380px"}}
        />
        <br />
        <Typography variant="h6" sx={{opacity: "70%",  width: "70%"}}>
            {appInfo.appOwner}, v{appInfo.appVersion}
        </Typography>
      </Stack>
    )
}

export default LogoBox