import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, FormControl, 
    IconButton, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import StraightenIcon from '@mui/icons-material/Straighten';
import ChatIcon from '@mui/icons-material/Chat';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import CallIcon from '@mui/icons-material/Call';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const VARIANT = "contained"

const BackButton = () => {

    const navigate = useNavigate()

    return (
        <Button 
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
        >
            Назад
        </Button> 
    )
}

export {BackButton}

const SizesButton = ({sizesVisible, setSizesVisible}) => {
    return (
        <IconButton size="large"
            onClick={() => setSizesVisible(!sizesVisible)}
            color={sizesVisible ? "secondary" : "primary"}
        >
            <StraightenIcon fontSize="inherit" />
        </IconButton>
    )
}

export {SizesButton}

const AnimationButton = ({animation, setAnimation}) => {
    return (
        <IconButton size="large"
            onClick={() => setAnimation(!animation)}
            color={animation ? "secondary" : "primary"}
        >
            {animation 
                ? <PauseIcon fontSize="inherit" />
                : <PlayArrowIcon fontSize="inherit" />
            }
        </IconButton>
    )
}

export {AnimationButton}

const InfoButton = ({infoVisible, setInfoVisible}) => {

    return (
        <IconButton size="large" 
            onClick={() => setInfoVisible(!infoVisible)}
            color={infoVisible ? "secondary" : "primary"}
        >
            <ChatIcon fontSize="inherit" />
        </IconButton>
    )
}

export {InfoButton}

const ArButton = ({mv}) => {

    const handleClick = () => {
        mv.activateAR()
    }

    return (
        <IconButton size="large" slot="ar-button" color="primary"
            onClick={handleClick}
        >
            <ViewInArIcon fontSize="inherit" />
        </IconButton>
    )
}

const CallButton = () => {
    return (
        <IconButton size="large" color="primary"
            href="https://addon-edu.ru/contacts/"
            target="_blank"
            rel="noreferrer"
        >
            <CallIcon fontSize="inherit" />
        </IconButton>
    )
}

const ItemSelect = ({items, itemNumber, setItemNumber, setAnimation, setInfoVisible}) => {

    const handleChange = (event) => {
        setItemNumber(event.target.value)
        setAnimation(true)
        setInfoVisible(false)
    }

    return (
        <FormControl  
            size="small"
            variant="outlined"
            sx={{textAlign: "left", minWidth: 145}}
            disabled={items.length === 1}
        >
            <InputLabel id="config-select-label">Конфигурация</InputLabel>
            <Select labelId="config-select-label"
                id="config-select"
                value={itemNumber}
                label="Конфигурация"
                onChange={handleChange}
                defaultValue={1}
            >
                {items?.map(item =>
                    <MenuItem key={item.id}
                        value={item.id-1}
                    >
                        {item.name}
                    </MenuItem>
                )}
                {/* <MenuItem value={0}>Заводская версия</MenuItem>
                <MenuItem value={1}>Двойной обдув</MenuItem>
                <MenuItem value={2}>Крепеж филамента + Обдув</MenuItem> */}
            </Select>
        </FormControl>
    )
}

const AnimationSelect = ({mv, animationName, setAnimationName}) => {

    const [animations, setAnimations] = useState([])
    const [disableButton, setDisableButton] = useState(false)

    useEffect(() => {
        console.log(mv?.availableAnimations)
    }, [])
    
    const handleClick = () => {
        mv.availableAnimations.length === 0 && setDisableButton(true)
        setAnimations(mv.availableAnimations)
    }

    const handleChange = (event) => {
        setAnimationName(event.target.value)
    }

    return (
        animations.length <= 1 
            ? <Button 
                disabled={disableButton}
                onClick={handleClick} sx={{minWidth: 145}}
            >
                анимации
            </Button>
            : <FormControl  
                size="small"
                variant="outlined"
                sx={{textAlign: "left", minWidth: 145}}
                onClick={handleClick}
            >
                <InputLabel id="animantion-select-label">Анимация</InputLabel>
                <Select labelId="animation-select-label"
                    id="animation-select"
                    value={animationName}
                    label="Анимация"
                    onChange={handleChange}
                    defaultValue={1}
                >
                    {animations.map(animation =>
                        <MenuItem key={animation}
                            value={animation}
                        >
                            {animation}
                        </MenuItem>    
                    )}
                </Select>
            </FormControl>
    )
}
            
const ControlPanel = (props) => {
    const {
        sizesVisible, setSizesVisible,
        infoVisible, setInfoVisible,
        animation, setAnimation,
        items,
        itemNumber, setItemNumber,
        mv, animationName, setAnimationName
    } = props

    return (
        <div className='annotation'>
            <Stack direction="row" spacing={1} sx={{mt: 1, width: 300}}>
                <ItemSelect 
                    items={items}
                    itemNumber={itemNumber}
                    setItemNumber={setItemNumber}
                    setAnimation={setAnimation}
                    setInfoVisible={setInfoVisible}
                />
                <AnimationSelect 
                    mv={mv} 
                    animationName={animationName}
                    setAnimationName={setAnimationName}
                />
            </Stack>
            <Stack direction='row'
                justifyContent="space-around" 
                alignItems="center"
                sx={{mt: 1}}
                divider={
                    <Divider orientation="vertical" variant="middle" flexItem  />
                }
            >
                <SizesButton 
                    sizesVisible={sizesVisible} 
                    setSizesVisible={setSizesVisible}
                />
                <InfoButton 
                    infoVisible={infoVisible}
                    setInfoVisible={setInfoVisible}
                />
                <AnimationButton 
                    animation={animation}
                    setAnimation={setAnimation}
                />
                <ArButton mv={mv} />
                <CallButton />
            </Stack>
        </div>
    )
}

export {ControlPanel}
