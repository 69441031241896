import { Box, Typography, Tooltip } from "@mui/material"

const AnnotationsMask = ({infoVisible, annotations}) => {
    return (<>
        {infoVisible && 
            annotations.map(annotation => <Box
                key={annotation[0]}
                slot={annotation[0]} 
                data-surface={annotation[1]}
            >
                <Tooltip arrow title={
                    <Typography>
                        {annotation[3]}
                    </Typography>
                }>
                    <Typography 
                        sx={{maxWidth: 200, fontWeight: 600, borderRadius: 3, bgcolor: "white", p: 0.5, fontSize: 12, px: 1}}
                    >
                        {annotation[2]}
                    </Typography>
                </Tooltip>
            </Box>)
        }
    </>)
} 

export {AnnotationsMask}