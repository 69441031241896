import { Outlet } from "react-router-dom"
import Header from "./Header";
import { CssBaseline } from "@mui/material";

export default function Layout() {

    return (
        <div className="App">
            <CssBaseline />
            <Header />
            <Outlet />
        </div>
    )
}