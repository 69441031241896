import { useState } from "react";
import { useNavigate } from "react-router-dom"
import { AppBar, Slide, Box, Button, Container, Drawer, IconButton, MenuItem, Stack, Toolbar, useScrollTrigger } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import logo from "../assets/header_logo.png"

// const pages = {
//     "Каталог": "/",
//     "О компании": "/",
//     "Контакты": "/"
// }

const DrawerMenu = ({open, onClose}) => {

    const navigate = useNavigate()

    return (
        <Drawer 
            anchor="top"
            open={open}
            onClose={onClose}
        >
            <Button onClick={() => navigate('/')}>
                Каталог
            </Button>
            <Button
                href="https://addon-edu.ru/"
                target="_blank"
                rel="noreferrer"
            >
                О компании
            </Button>
            <Button
                href="https://addon-edu.ru/contacts/"
                target="_blank"
                rel="noreferrer"
            >
                Контакты
            </Button>
        </Drawer>
    )
}

function HideOnScroll(props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children ?? <div />}
      </Slide>
    );
}
  
HideOnScroll.propTypes = {
    children: PropTypes.element,
    window: PropTypes.func,
};

function Header(props) {
    
    const navigate = useNavigate()

    const [drawer, setDrawer] = useState(false)

    return (
        <HideOnScroll>
            <AppBar position="fixed"
                sx={{boxShadow: 0, height: "50px", 
                    display: 'flex', 
                    justifyContent: 'center',
                    alignItems: 'center', bgcolor: "primary.main"
                }}
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Stack
                            direction="column"
                            spacing={0.2}
                        >
                            <img src={logo} alt="Addon logo #1" style={{height: "25px"}}/>
                        </Stack>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: "flex-end" }}>
                            <IconButton 
                                color="inherit"
                                onClick={() => setDrawer(true)}
                            >
                                <MenuIcon color="#ffffff" />
                            </IconButton>
                            <DrawerMenu open={drawer} onClose={() => setDrawer(false)}/>
                        </Box>
                        <Box 
                            sx={{ ml: 2, flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "flex-end" }}
                        >
                            <Stack direction="row" spacing={1}>
                                <Button
                                    onClick={() => navigate('/')}
                                    sx={{color: "white"}}
                                >
                                    Каталог
                                </Button>
                                <Button
                                    href="https://addon-edu.ru/"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={{color: "white"}}
                                >
                                    О компании
                                </Button>
                                <Button 
                                    href="https://addon-edu.ru/contacts/"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={{color: "white"}}
                                >
                                    Контакты
                                </Button>
                            </Stack>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </HideOnScroll>
    )
}

export default Header